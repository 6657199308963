<template>
  <section class="main-section storage-section">
    <div class="storage-1">
      <div class="storage-1-img font-0"><img :src="ossPath + 'storage-1.png'" class="full-img" /></div>
      <div class="storage-label-1 font-0"><img :src="ossPath + 'cabinet-label-1.png'" class="full-img" /></div>
    </div>
    <div class="storage">
      <div class="storage-title">SITTING ROOM 客厅柜</div>
    </div>
    <div class="storage">
      <div class="storage-img font-0">
        <img :src="ossPath + 'storage-2.png'" class="full-img" />
      </div>
      <div class="storage-title storage-title-right">DINNING ROON 餐边柜</div>
    </div>
    <div class="storage">
      <div class="storage-img font-0">
        <img :src="ossPath + 'storage-3.png'" class="full-img" />
      </div>
      <div class="storage-title">PORCH 玄关柜</div>
    </div>
    <div class="storage-3">
      <div class="storage-3-img font-0"><img :src="ossPath + 'storage-4.png'" class="full-img" /></div>
      <div class="storage-label-2 font-0"><img :src="ossPath + 'cabinet-label-2.png'" class="full-img" /></div>
      <div class="storage-title storage-title-right">BATHROOM 卫浴柜</div>
    </div>
    <div class="storage-more">
      <a class="storage-btn" href="/space-storage-more">点击了解更多</a>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/'
  }),
  created () {
    this.$store.commit('updateActiveNav', 1)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/space.css';
</style>
